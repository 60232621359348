import React from 'react';
import styled from 'styled-components';
import Layout from '../../layout/main';
import SEOComponent from '../seo';

const Content = styled.div`
    margin-left: 64px;
    margin-right: 64px;
    margin-top: 100px;
    margin-bottom: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
        margin-top: 92px;
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 130px;
    }
`;

const Sow = ({ powerForm }) => {
    const baseUrl =
        'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=';
    const url = `${baseUrl}${powerForm}&env=na3&acct=${process.env.GATSBY_DOCUSIGN_ACCOUNT_ID}&v=2`;

    return (
        <Layout>
            <SEOComponent
                title="For Publishers"
                image={''}
                description={'SOW Contract'}
            />
            <Content>
                <iframe
                    id="iframe"
                    className="power-form"
                    title="Adugient Publisher SOW"
                    width="90%"
                    height="800"
                    loading="eager"
                    src={url}
                />
            </Content>
        </Layout>
    );
};

export default Sow;
